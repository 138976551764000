<template>
    <div v-if="!access">
        <NotFound />
    </div>
    <div v-else>
        <div class="pb-2 mb-3 border-bottom">
            <h2>Slab Earning Deductions</h2>
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item">
                    <router-link :to="{name: 'earning-deductions'}">Earning Deductions</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">Slab Earning Deductions</li>
            </ol>
        </nav>
        <b-container fluid>
            <div v-if="api_error">
                <b-alert
                    :show="alertDismissCountDown"
                    dismissible
                    variant="danger"
                    @dismissed="alertDismissCountDown=0"
                    @dismiss-count-down="alertCountDownChanged"
                >{{api_error}}</b-alert>
            </div>
            <b-row>
                <b-col lg="4">
                    <b-form-group
                        label="Salary Slab"
                        label-cols-sm="3"
                        label-align-sm="left"
                        label-size="sm"
                        class="mb-1"
                        label-for="slabFilter"
                        style="font-weight:bold;font-size:1rem !important;"
                    >
                        <b-input-group >
                            <b-form-select 
                                id="slabFilter" 
                                v-model="urlBody.slab_id" 
                                :options="salary_slabs" 
                                disabled-field="notEnabled"
                                >
                            </b-form-select>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col lg="2">
                        <button
                            type="button"
                            class="btn btn-primary text-left "
                            v-on:click="onSelection()"
                        >Search</button>
                </b-col>
            </b-row>
            <br> <br>
            <div v-if="type_one">
                <h4 class="my-3">Slab Earnings</h4>
                <b-card>
                    <b-row>
                        <b-col lg="6" class="mb-1rem">
                            <router-link
                                v-if="hasPermission('CAN_MANAGE_EARNING_DEDUCTIONS')"
                                :to="{  name: 'create-slab-earning-deductions', 
                                        params: { type:'earning', slab_id: this.urlBody.slab_id} }"
                                class="btn btn-primary text-right"
                                tag="button"
                            >
                                <font-awesome-icon icon="plus"></font-awesome-icon>&nbsp; Create Slab Earning
                            </router-link>
                        </b-col>
                    </b-row>

                    <b-table
                        show-empty
                        striped
                        hover
                        :items="type_two"
                        :fields="fields"
                        responsive
                    >
                        <template v-slot:cell(payroll_formula.conditions)="row">
                            <div class="row">
                                <div class="col-md-12">
                                    <span v-if="JSON.parse(row.item.payroll_formula.conditions).length>1">Default Formula : </span>{{ JSON.parse(row.item.payroll_formula.conditions)[JSON.parse(row.item.payroll_formula.conditions).length-1].formula }}
                                </div>
                            </div>
                            <div class="row mt-3" v-for="(condition, index) in JSON.parse(row.item.payroll_formula.conditions).slice(0, JSON.parse(row.item.payroll_formula.conditions).length-1)" :key="index">
                                <div class="col-md-12" v-for="(value,name,index2) in condition" :key="index2">
                                    <span>{{ str_title(name) }} {{ index+1 }}: {{ value }}</span>
                                </div>
                            </div>
                        </template>
                        <template v-slot:cell(values)="row">
                           <span v-if="row.item.values.length > 2"> {{ row.item.values }} </span> 
                        </template>
                        <template v-slot:cell(actions)="row">
                            <span class="actions">
                                <router-link
                                    v-b-tooltip.hover title="Edit"
                                    v-if="hasPermission('CAN_MANAGE_EARNING_DEDUCTIONS')"
                                    class="circle"
                                    :to="{  name: 'edit-slab-earning-deductions', 
                                            params: { id:  row.item.id,slab_id: row.item.slab_id,type:row.item.type}}"
                                >
                                    <font-awesome-icon icon="pen"></font-awesome-icon>
                                </router-link>
                                <!-- <span>
                                    <font-awesome-icon
                                        v-if="hasPermission('CAN_MANAGE_SALARY_SLABS')"
                                        icon="trash-alt"
                                        hidden
                                        @click="info(row.item, row.index, $event.target)"
                                        class="mr-1 text-danger"
                                        style="cursor:pointer"
                                        v-b-modal.modal-center
                                    ></font-awesome-icon>
                                </span> -->
                                <span class="ml-1 text-danger circle" v-b-tooltip.hover title="Deactivate" @click="statusInfoModal(row.item, row.index, $event.target)" v-if="hasPermission('CAN_MANAGE_EARNING_DEDUCTIONS') && row.item.status">
                                    <font-awesome-icon
                                        icon="ban"
                                        v-b-modal.modal-center
                                    ></font-awesome-icon>
                                </span>
                                <span class="ml-1 circle" @click="openHistory(row.item)"  v-b-tooltip.hover title="History">
                                    <font-awesome-icon
                                        icon="history"
                                        v-b-modal.modal-center
                                        style="cursor: pointer;" 
                                    ></font-awesome-icon>
                                </span>
                            </span>
                           
                        </template>
                        <template v-slot:cell(status)="row">
                            <h6 v-if="row.item.status"><b-badge variant="success">ACTIVE</b-badge></h6>
                            <h6 v-else><b-badge variant="danger">INACTIVE</b-badge></h6>
                        </template>
                    </b-table>
                </b-card>
            </div>
            <br> <hr v-if="type_two"> <br>
            <div v-if="type_two">
                 <h4 class="my-3">Slab Deductions</h4>
                <b-card>
                    <b-row>
                        <b-col lg="6" class="mb-1rem">
                            <router-link
                                v-if="hasPermission('CAN_MANAGE_EARNING_DEDUCTIONS')"
                                :to="{  name: 'create-slab-earning-deductions', 
                                        params: { type:'deduction',slab_id: this.urlBody.slab_id }}"
                                class="btn btn-primary text-right"
                                tag="button"
                            >
                                <font-awesome-icon icon="plus"></font-awesome-icon>&nbsp; Create Slab Deduction
                            </router-link>
                        </b-col>
                    </b-row>

                    <b-table
                        show-empty
                        striped
                        hover
                        :items="type_one"
                        :fields="fields"
                        responsive
                    >
                        <template v-slot:cell(payroll_formula.conditions)="row">
                            <div class="row">
                                <div class="col-md-12">
                                    <span v-if="JSON.parse(row.item.payroll_formula.conditions).length>1">Default Formula : </span>{{ JSON.parse(row.item.payroll_formula.conditions)[JSON.parse(row.item.payroll_formula.conditions).length-1].formula }}
                                </div>
                            </div>
                            <div class="row mt-3" v-for="(condition, index) in JSON.parse(row.item.payroll_formula.conditions).slice(0, JSON.parse(row.item.payroll_formula.conditions).length-1)" :key="index">
                                <div class="col-md-12" v-for="(value,name,index2) in condition" :key="index2">
                                    <span>{{ str_title(name) }} {{ index+1 }}: {{ value }}</span>
                                </div>
                            </div>
                        </template>
                        <template v-slot:cell(values)="row">
                           <span v-if="row.item.values.length > 2"> {{ row.item.values }} </span> 
                        </template>
                        <template v-slot:cell(actions)="row">
                            <span class="actions">
                                <router-link
                                        class="circle"
                                        v-b-tooltip.hover title="Edit"
                                        v-if="hasPermission('CAN_MANAGE_EARNING_DEDUCTIONS')"
                                        :to="{  name: 'edit-slab-earning-deductions', 
                                                params: { id:  row.item.id,slab_id: row.item.slab_id,type:row.item.type}}"
                                    >
                                    <font-awesome-icon icon="pen"></font-awesome-icon>
                                </router-link>
                                <!-- <span>
                                    <font-awesome-icon
                                        v-if="hasPermission('CAN_MANAGE_SALARY_SLABS')"
                                        icon="trash-alt"
                                        hidden
                                        @click="info(row.item, row.index, $event.target)"
                                        class="mr-1 text-danger"
                                        style="cursor:pointer"
                                        v-b-modal.modal-center
                                    ></font-awesome-icon>
                                </span> -->
                                <span class="ml-1 circle text-danger" @click="statusInfoModal(row.item, row.index, $event.target)" v-b-tooltip.hover title="Inactive Status" v-if="hasPermission('CAN_MANAGE_EARNING_DEDUCTIONS') && row.item.status">
                                    <font-awesome-icon
                                        icon="ban"
                                        v-b-modal.modal-center
                                    ></font-awesome-icon>
                                </span>
                                <span class="ml-1 circle" @click="openHistory(row.item)"  v-b-tooltip.hover title="History">
                                    <font-awesome-icon
                                        icon="history"
                                        v-b-modal.modal-center
                                        style="cursor: pointer;" 
                                    ></font-awesome-icon>
                                </span>
                            </span>
                            
                        </template>
                        <template v-slot:cell(status)="row">
                            <h6 v-if="row.item.status"><b-badge variant="success">ACTIVE</b-badge></h6>
                            <h6 v-else><b-badge variant="danger">INACTIVE</b-badge></h6>
                        </template>
                    </b-table>
                </b-card>
            </div>
            <br> <br> <br>
            <b-modal 
                :id="history.id" 
                centered  
                size="xl" 
                hide-footer
            >
                <template #modal-title>
                    <span class="h4 font-weight-bold">{{history.title}}</span> 
                </template>
                <b-table
                    show-empty
                    striped
                    hover
                    :items="history.rows"
                    :fields="history.fields"
                    no-local-sorting
                    responsive
                >
                 <template v-slot:cell(status)="row">
                    <h6 v-if="row.item.status =='1'"><b-badge variant="success">ACTIVE</b-badge></h6>
                    <h6 v-else><b-badge variant="danger">INACTIVE</b-badge></h6>
                </template>
                </b-table>
            </b-modal>
            <!-- Info modal -->
            <b-modal
                :id="infoModal.id"
                :title="infoModal.title"
                centered
                size="sm"
                @hide="resetInfoModal"
            >
                <p v-html="message" class="float-left"></p>
                <div slot="modal-footer" class="w-100">
                    <b-button class="mt-3 float-right" @click="cancel()">Cancel</b-button>
                    <b-button
                        class="mt-3 float-right"
                        variant="danger"
                        v-on:click="deleteEarningDeduction(infoModal.content)"
                        style="margin-right:10px;"
                    >Delete</b-button>
                </div>
            </b-modal>

            <!-- Status modal -->
            <b-modal
                :id="statusModal.id"
                :title="statusModal.title"
                centered
                size="md"
                @hide="resetStatusInfoModal"
            >
                <p v-html="message" class="float-left"></p>
                <div slot="modal-footer" class="w-100">
                    <b-button class="mt-3 float-right" @click="cancelStatusModal()">No</b-button>
                    <b-button
                        class="mt-3 float-right"
                        variant="danger"
                        v-on:click="updateStatusEarningDeduction(statusModal.content)"
                        style="margin-right:10px;"
                    >Yes</b-button>
                </div>
            </b-modal>

        </b-container>
    </div>
</template>

<script>
import NotFound from "../../../errors/NotFound";
import default_error from "../../../../assets/js/global";
export default {
    components: {
        NotFound
    },
    data() {
        return {
            urlBody: {
                query: "",
                sort_by: "",
                order: "",
                type:"",
                slab_id:"",
            },
            api_error: "",
            sortBy: "name",
            sortDesc: false,
            fields: [
                { key: "name", sortable: false},
                { key: "payroll_formula.conditions", label:"Formula", sortable: false, tdClass: 'tableWidth'},
                { key: "values", sortable: false},
                { key: "status", sortable: false},
                { key: "actions", label: "Actions", thStyle: { width: '10%' } }
            ],
            type_one: null,
            type_two:null,
            access: false,
            alertDismissCountDown: 0,
            alertDismissSecs: 10,
            infoModal: {
                id: "modal-sm",
                title: "",
                content: ""
            },
            statusModal: {
                id: "status-modal",
                title: "",
                content: ""
            },
            message:"",
            salary_slabs: [{text:"Select Slab", value:"" }],
            history: {
                id: 'history-modal',
                title: 'History',
                fields: [
                    { key: "name", sortable: false},
                    { label:"Formula", key: "formula", sortable: false},
                    { key: "values", sortable: false},
                    { key: "status", sortable: false},
                    { label: "Updated by",key: "updated_by_name", sortable: false },
                    { key: "updated_at", sortable: false },
                ],
                rows: []
            },

        };
    },
    mounted() {
        this.access = this.hasPermission("CAN_MANAGE_EARNING_DEDUCTIONS");
        if(this.$route.query.slab_id) {
            this.urlBody.slab_id = this.$route.query.slab_id;
        }
        this.getEmployeesSalarySlabs();
        this.onPageLoad();
    },
    methods: {
        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },
        
        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },

        onSelection() {
            this.$router.push({name:'slab-earning-deductions', query:{slab_id: this.urlBody.slab_id}})
            this.getDEDTypeOne();
            this.getDEDTypeTwo();
            
        },

        onPageLoad:function(){
            this.getDEDTypeOne();
            this.getDEDTypeTwo();
        },

        statusInfoModal(item, index, button) {
            this.statusModal.title = `Status Confirmation`;
            this.message = 'Are you sure you want to Deactivate <b>'+this.str_title(item.name)+'</b> Slab '+this.str_title(item.type)+ '?';
            this.statusModal.content = item;
            this.$root.$emit("bv::show::modal", this.statusModal.id, button);
        },

        resetStatusInfoModal() {
            this.statusModal.title = "";
            this.statusModal.content = "";
        },

        cancelStatusModal: function() {
            this.$root.$emit("bv::hide::modal", this.statusModal.id);
        },

        updateStatusEarningDeduction: function(slabEarningDeductions) {
            this.startProgressBar();
            let query = this.getAuthHeaders();
            query['params'] = {};
            let data = {
                id: slabEarningDeductions.id,
                status:0
            }
            console.log(query['params']);
            this.axios
                .post(
                    this.$api.update_status_slab_earning_deduction,
                    data,
                    query
                )
                .then(response => {
                    if (response.data.code == 200) {
                        this.$root.$emit("bv::hide::modal", this.statusModal.id);
                        this.success = response.data.message;
                        this.getDEDTypeOne();
                        this.getDEDTypeTwo();
                    }
                    this.updateProgressBar(true);
                })
                .catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                        console.error(err.response.data);
                    }
                    this.updateProgressBar(false);
                    this.showAlert();
                    window.scrollTo(0, 0);
                });
        },

        info(item, index, button) {
            this.infoModal.title = `Delete Confirmation`;
            this.message = 'Are you sure you want to Delete this?';
            this.infoModal.content = JSON.stringify(item, null, 2);
            this.$root.$emit("bv::show::modal", this.infoModal.id, button);
        },

        resetInfoModal() {
            this.infoModal.title = "";
            this.infoModal.content = "";
        },

        openHistory(item) {
            this.history.rows = JSON.parse(item.history);
            this.$root.$emit("bv::show::modal", this.history.id);
        },

        deleteEarningDeduction: function(slabEarningDeductions) {
            this.startProgressBar();
            let query = this.getAuthHeaders();
            query['params'] = {};
            this.axios
                .post(
                    this.$api.delete_slab_earning_deduction,
                    JSON.parse(slabEarningDeductions),
                    query
                )
                .then(response => {
                    if (response.data.code == 200) {
                        this.$root.$emit("bv::hide::modal", this.infoModal.id);
                        this.success = response.data.message;
                        this.getDEDTypeOne();
                        this.getDEDTypeTwo();
                    }
                    this.updateProgressBar(true);
                })
                .catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                        console.error(err.response.data);
                    }
                    this.updateProgressBar(false);
                    this.showAlert();
                    window.scrollTo(0, 0);
                });
        },

        cancel: function() {
            this.$root.$emit("bv::hide::modal", this.infoModal.id);
        },


        getDEDTypeOne: function() {
            this.startProgressBar();
            var query = this.getAuthHeaders();
            this.urlBody.type= "deduction";
            query['params']=this.deleteEmptyKeys(this.urlBody);
            this.axios.get(this.$api.get_slab_earning_deductions, query)
                .then(response => {
                    this.type_one = response.data.data;
                    this.api_error = "";
                    if(!this.urlBody.slab_id) {
                        this.type_one= null
                    }
                    this.updateProgressBar(true);
                }).catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                        console.error(err.response.data);
                    }
                    this.type_one = [];
                    this.updateProgressBar(false);
                    this.showAlert();
                });
        },

        getDEDTypeTwo: function() {
            this.startProgressBar();
            var query = this.getAuthHeaders();
            this.urlBody.type= "earning";
            query['params']=this.deleteEmptyKeys(this.urlBody);
            this.axios.get(this.$api.get_slab_earning_deductions, query)
                .then(response => {
                    this.type_two = response.data.data;
                    this.api_error = "";
                    if(!this.urlBody.slab_id) {
                        this.type_two= null
                    }
                    this.updateProgressBar(true);
                }).catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                        console.error(err.response.data);
                    }
                    this.type_one = [];
                    this.updateProgressBar(false);
                    this.showAlert();
                });
        },

        getEmployeesSalarySlabs() {
            this.startProgressBar();
            var query = this.getAuthHeaders();
            query["params"] = {};
            this.axios.get(this.$api.get_salary_slabs, query)
                .then(response => {
                    if (response.data.code == 200) {
                        var result = response.data.data;
                        result.forEach(employee_salary_slab => {
                            this.salary_slabs.push({
                                value: employee_salary_slab.id,
                                text: employee_salary_slab.name
                            });
                        });
                    } else {
                        this.salary_slabs = [];
                    }
                    // this.urlBody.slab_id = result[0].id
                    this.api_error = "";
                    this.updateProgressBar(true);
                }).catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                        console.error(err.response.data);
                    }
                    this.updateProgressBar(false);
                    this.showAlert();
                });
        },
    }
};
</script>
<style lang="scss">
@import "../../../../assets/css/custom.scss";
.tableWidth {
    max-width: 350px;
}
</style>
